import React from 'react';
import clsx from 'clsx';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/src/styles/prism';

interface MacroCodeBlockProps {
    codeString: string;
    language?: string;
    showLineNumbers?: boolean;
    className?: string; // Additional CSS classes for styling
}

export default function MacroCodeBlock({
    codeString,
    language = 'javascript',
    showLineNumbers = true,
    className,
}: MacroCodeBlockProps) {
    const classes = clsx(
        'code-block',
        className
    );

    return (
        <div className={classes}>
            <div className="code-block__wrapper">
                <SyntaxHighlighter language={language} style={vscDarkPlus} showLineNumbers={showLineNumbers}>
                    {codeString}
                </SyntaxHighlighter>
            </div>
        </div>
    );
}
