import React from 'react';
import clsx from 'clsx';

// components
import Section from '../Section';
import LabelText from '../LabelText';
import Image from '../Image';
import Button from '../Button';

// prop types
interface SingleItemProps {
  layout?: 'default' | 'reverse' | 'fullWidth';
  labelText?: string;
  bulletColor?: 'black' | 'accent-1' | 'accent-2' | 'accent-3' | 'accent-4' | 'accent-5' | 'accent-6';
  backgroundColor?: undefined | 'white' | 'primary' | 'secondary' | 'orangeTwine' | 'brownSorell' | 'greenSmoke' | 'redRust' | 'lavender';
  title?: string;
  subtitle?: string;
  extraText?: string;
  buttons?: React.ReactElement<typeof Button>[];
  primaryLink?: string;
  secondaryLink?: string;
  dividerTop?: boolean;
  dividerBottom?: boolean;
  videoSrc?: string;
  videoAspectRatio?: '4x5' | '9x16';
  imgSrc?: string,
  imgSrcMedium?: string,
  imgSrcSmall?: string,
  imgAlt?: string,
  imgWidth?: number,
  imgCaption?: string
  className?: string;
  strings?: any;

  // section props
  section?: {
    dividerTop?: boolean;
    dividerBottom?: boolean;
  }
}

export default function SingleItem({
  layout = 'default',
  labelText,
  bulletColor = 'accent-1',
  backgroundColor = undefined,
  title,
  subtitle,
  extraText,
  buttons,
  primaryLink,
  secondaryLink,
  videoSrc,
  videoAspectRatio = '4x5',
  imgSrc,
  imgSrcMedium,
  imgSrcSmall,
  imgAlt,
  imgWidth,
  imgCaption,
  section,
  className,
  strings,
}: SingleItemProps) {
  const classes = clsx(
    'single-item',
    `single-item--${layout}`,
    `single-item--${backgroundColor}`,
    `single-item--video-${videoAspectRatio}`,
    `${backgroundColor ? 'single-item--background' : ''}`,
    className,
  );
  return (
    <Section className={classes} dividerTop={section?.dividerTop} dividerBottom={section?.dividerBottom}>
      <div className="grid-container">
        <div className="single-item__wrapper">
          <div className="grid-row">
            <div className={`single-item__content c-md-${layout === 'fullWidth' ? '12' : '6'}`}>
              {labelText && (
                <LabelText text={labelText} bulletColor={bulletColor} className="single-item__label" />
              )}
              {title && <h2 className="single-item__title">{title}</h2>}
              {subtitle && <p className='single-item__subtitle'>{subtitle}</p>}
              {extraText && <p className="single-item__extra-text">{extraText}</p>}
              {buttons.length > 0 && <div className="single-item__buttons">
                {buttons.map((button, index) => (
                  <React.Fragment key={index}>
                    {button}
                  </React.Fragment>
                ))}
              </div>}
            </div>
            {((imgSrc || videoSrc) && layout !== 'fullWidth') && (
              <div className="single-item__figure-wrapper c-md-6">
                {(primaryLink && !videoSrc) &&
                  <a href={primaryLink} className="single-item__image-link absolute-link" aria-label={strings?.readMore}></a>
                }

                <figure className='single-item__figure'>
                  {(imgSrc && !videoSrc) && (
                    <Image
                      src={imgSrc}
                      width={imgWidth}
                      srcMedium={imgSrcMedium}
                      srcSmall={imgSrcSmall}
                      alt={imgAlt}
                      sizes="(max-width: 768px) 100vw"
                    />
                  )}
                  {videoSrc && (
                    <video loop playsInline autoPlay muted controls>
                      <source src={videoSrc} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                  {imgCaption && <figcaption className="single-item__caption caption">{imgCaption}</figcaption>}
                </figure>
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}
